@use 'photoswipe/src/css/default-skin/default-skin';
@use 'photoswipe/src/css/main';

@use 'mixins' as *;

.gallery {
  margin: -10px;
  display: flex;
  flex-wrap: wrap;

  .gallery-item {
    padding: 10px;

    @include mobile {
      width: 100%;
    }

    @include desktop {
      width: calc(100% / 3);
    }

    .img-container {
      position: relative;
      height: 0;
      // .gallery-item have a aspect ratio of 3:2
      padding-bottom: calc(100% * (2 / 3));

      img {
        // Covers the parent element completely, no matter which size it has
        position: absolute;
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: top;
        border-radius: 4px;

        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 15%);
        box-shadow: 0 0px 12px 2px rgb(0 0 0 / 12%);
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
          transform 280ms ease-in-out;

        @include desktop {
          &:hover {
            transform: scale(1.05);
            box-shadow: 0 0px 20px 2px rgb(0 0 0 / 20%);
          }
        }
      }
    }
  }
}

// Override PhotoSwipe top bar opacity
.pswp__ui--fit .pswp__top-bar,
.pswp__top-bar {
  background-color: rgba(0, 0, 0, 0);
}

.pswp--zoom-allowed .pswp__img,
.pswp__img {
  box-shadow: 0px 0px 50px 10px rgb(0 0 0 / 14%);
}
