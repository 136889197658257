@use 'variables' as *;

// Import Bootstrap variables
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Override Bootstrap's variables
$body-bg: $base-color;
$body-color: $font-color;
$font-family-base: Inter, $font-family-sans-serif;

// Import Bootstrap CSS
// Ordered following https://github.com/twbs/bootstrap/blob/v5.0.0-beta1/scss/bootstrap.scss
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';

:root {
  // Copied from https://github.com/twbs/bootstrap/blob/v5.0.0-beta1/scss/mixins/_grid.scss
  --#{$variable-prefix}gutter-x: #{$grid-gutter-width};

  --container-width: 100vw;
  // Copied from https://github.com/twbs/bootstrap/blob/v5.0.0-beta1/scss/_containers.scss
  @each $breakpoint, $container-max-width in $container-max-widths {
    @if ($container-max-width < $container-width-desktop) {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        --container-width: #{$container-max-width};
      }
    }
  }
}
